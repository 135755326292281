import {
    createPlateEditor,
    Plate,
    PlateContent,
    PlateElement,
    PlateLeaf,
    useEditorRef,
    useEditorContainerRef,
} from '@udecode/plate/react';
import {
    BoldPlugin,
    ItalicPlugin,
    UnderlinePlugin,
} from '@udecode/plate-basic-marks/react';
import {HeadingPlugin} from '@udecode/plate-heading/react';
import {BlockquotePlugin} from '@udecode/plate-block-quote/react';
import {ListPlugin} from '@udecode/plate-list/react';
import {SoftBreakPlugin} from '@udecode/plate-break/react';
import {LinkPlugin} from '@udecode/plate-link/react';
import {MarkdownPlugin, serializeMdNodes} from '@udecode/plate-markdown';
import {HorizontalRulePlugin} from '@udecode/plate-horizontal-rule/react';
import { CursorOverlayPlugin } from '@udecode/plate-selection/react';
import {remarkTransformElementChildren, remarkDefaultElementRules} from "@udecode/plate-markdown";

import {
    useCallback,
    useEffect,
    useState,
    useRef, useMemo,
} from "react";
import Toolbar from "./Toolbar";
import styles from "./styles/SlateEditor.module.scss";
import classNames from "classnames";
import {debounce} from "../../utils/CleversiteUtilities";
import FontAwesome from "../utilities/FontAwesome";
import {MarkType, NodeType} from "./utils";
import {CursorOverlay} from "./CursorOverlay";

export interface MarkdownEditorRef {
    forceUpdateState: (md: string) => void,
}

export const defaultValue = [
    {
        type: NodeType.PARAGRAPH,
        children: [{text: ""}],
    },
];

function EditorContainer(props) {
    const editor = useEditorRef();
    const containerRef = useEditorContainerRef();

    return <div id={editor.uid} ref={containerRef} {...props} />;
}

export const markdownDefaultMaxLength = 200000;

const MarkdownEditor = (props: {
    value: string,
    className?: string,
    editorClassName?: string,
    handleTextChange: (value: string, editor) => void,
    placeholder?: string,
    maxLength?: number,
    editable?: boolean,
}) => {
    const {maxLength = markdownDefaultMaxLength, editable = true} = props;
    const [editInMarkdown, setEditInMarkdown] = useState(false);

    const editor = useMemo(() => {
        const e = createPlateEditor({
            // prevent overriding default max length to a longer limit - default is more than enough and is in place
            // to prevent bogging down the system when converting markdown to HTML
            maxLength: Math.min(maxLength, markdownDefaultMaxLength),
            plugins: [
                HeadingPlugin,
                BlockquotePlugin,
                BoldPlugin,
                ItalicPlugin,
                UnderlinePlugin,
                ListPlugin,
                SoftBreakPlugin.configure({
                    options: {
                        rules: [
                            {hotkey: 'shift+enter'},
                        ],
                    },
                }),
                LinkPlugin,
                MarkdownPlugin.configure({
                    options: {
                        // remove when https://github.com/udecode/plate/pull/4194 is merged and released
                        elementRules: {
                            ...remarkDefaultElementRules,
                            blockquote: {
                                transform: (node, options) => {
                                    const children = node.children?.length
                                        ? node.children.flatMap((paragraph) =>
                                            remarkTransformElementChildren(paragraph, options)
                                        )
                                        : [{ text: '' }];

                                    // Flatten nested blockquotes (e.g. >>>)
                                    const flattenedChildren = children.flatMap((child: any) =>
                                        child.type === "blockquote" ? child.children : [child]
                                    );

                                    return {
                                        children: flattenedChildren,
                                        type: options.editor.getType({ key: 'blockquote' }),
                                    };
                                },
                            },
                        }
                    }
                }),
                HorizontalRulePlugin,
                CursorOverlayPlugin.configure({
                    render: ({afterEditable: () => <CursorOverlay />})
                }),
            ],
            override: {
                components: {
                    [MarkType.STRONG]: (props) => <PlateLeaf as={"strong"} {...props} />,
                    [MarkType.EMPHASIS]: (props) => <PlateLeaf as={"em"} {...props} />,
                    [MarkType.UNDERLINE]: (props) => <PlateLeaf as={"u"} {...props} />,
                    [NodeType.BLOCK_QUOTE]: (props) => <PlateElement as={"blockquote"} {...props} />,
                    [NodeType.HEADING_ONE]: (props) => <PlateElement as={"h1"} {...props} />,
                    [NodeType.HEADING_TWO]: (props) => <PlateElement as={"h2"} {...props} />,
                    [NodeType.HEADING_THREE]: (props) => <PlateElement as={"h3"} {...props} />,
                    [NodeType.HEADING_FOUR]: (props) => <PlateElement as={"h4"} {...props} />,
                    [NodeType.HEADING_FIVE]: (props) => <PlateElement as={"h5"} {...props} />,
                    [NodeType.HEADING_SIX]: (props) => <PlateElement as={"h6"} {...props} />,
                    [NodeType.PARAGRAPH]: (props) => <PlateElement as={"p"} {...props} />,
                    [NodeType.UNORDERED_LIST]: (props) => <PlateElement as={"ul"} {...props} />,
                    [NodeType.ORDERED_LIST]: (props) => <PlateElement as={"ol"} {...props} />,
                    [NodeType.LIST_ITEM]: (props) => <PlateElement as={"li"} {...props} />,
                    [NodeType.LINK]: (props) => <PlateElement as={"a"} {...props} />,
                    [NodeType.THEMATIC_BREAK]: (props) => <hr contentEditable={false}/>,
                }
            }
        });
        e.tf.setValue(props.value ? e.api.markdown.deserialize(props.value) : defaultValue);
        return e;
    }, [])

    const containerRef = useRef<HTMLDivElement | null>(null);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const slateEditorRef = useRef<HTMLDivElement | null>(null)

    function forceUpdateState(md) {
        editor.tf.reset(); // important to fix issue of lag between value changing and editor adjusting selection, see https://github.com/ianstormtaylor/slate/issues/3575
        editor.tf.setValue(editor.api.markdown.deserialize(md));
    }

    useEffect(() => {
        const scrollHeight = textareaRef.current?.scrollHeight
        if (textareaRef.current && scrollHeight) {
            textareaRef.current.style.height = scrollHeight.toString() + "px"
        }
    }, [textareaRef])

    useEffect(() => {
        const editor = slateEditorRef.current
        if (!editable && editor) {
            // @ts-ignore
            const child_style = editor.children[0].style
            child_style.minHeight = '0px';
        }
    }, [slateEditorRef])

    const handleTextChange = useCallback(debounce((value) => {
        props.handleTextChange(serializeMdNodes(editor.children, {
            // Not ideal but consistent with current behavior - prevents multiple line-breaks within a paragraph. To
            // remove this we will need to modify markdownToHtml conversion.
            ignoreParagraphNewline: true,
        }), editor);
    }, 750) as (value) => void, []);

    const containerClassName = classNames({
        [styles.container]: true,
        [styles.containerRawMarkdown]: editInMarkdown,
        [styles.containerEditable]: editable,
        "notranslate": true,
        [props.className || ""]: props.className,
    });

    const editorClassName = classNames({
        [styles.editor]: true,
        [props.editorClassName || ""]: props.editorClassName,
    })

    return (
        <div className={containerClassName} ref={containerRef}>
            {editInMarkdown ? <div className={editorClassName}>
                <textarea
                    placeholder={props.placeholder || "Enter some rich text..."}
                    value={props.value}
                    rows={editable ? 8 : undefined}
                    ref={textareaRef}
                    maxLength={maxLength}
                    onChange={e => {
                        forceUpdateState(e.target.value);
                        props.handleTextChange(e.target.value, editor);
                    }}
                    disabled={!editable}
                    style={editable ? {} : {resize: "none"}}
                />
            </div> : <Plate
                editor={editor}
                onValueChange={handleTextChange}
            >
                <EditorContainer className={styles.editorContainer}>
                    {editable && <Toolbar containerRef={containerRef}/>}
                    <div className={editorClassName} ref={slateEditorRef}>
                        <PlateContent
                            placeholder={props.placeholder || "Enter some rich text..."}
                            readOnly={!editable}
                        />
                    </div>
                </EditorContainer>
            </Plate>}
            {editable &&
                <>
                    <button
                        onClick={() => setEditInMarkdown(v => !v)}>{editInMarkdown ? "Edit in Rich Text" : "Edit in Markdown (advanced)"}</button>
                    {editInMarkdown || <span
                        className={styles.tooltip}
                        data-content={"Markdown is a lightweight markup\nlanguage used by SchoolBlocks.\nNote that not all markdown elements\nare supported."}
                        style={{marginLeft: ".25rem"}}
                    >
                <FontAwesome prefix={'fas'} name={'fa-question-circle'}/>
            </span>}
                </>}
        </div>
    );
};

export default MarkdownEditor;
